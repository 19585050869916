var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.division.showTeams)?_c('message-card',[_c('h2',{staticClass:"ma-12 text-center"},[_c('v-avatar',{attrs:{"color":"red"}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("fas fa-eye-slash")])],1),_c('br',{staticClass:"hidden-md-and-up"}),_vm._v(" The tournament host has not published the teams yet. ")],1)]):_c('v-data-table',{staticClass:"elevation-1",attrs:{"items":_vm.rows,"headers":_vm.headers,"loading":_vm.division.loading,"options":_vm.page,"search":_vm.search,"hide-default-footer":"","dense":""},on:{"update:options":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"color":"color3","indeterminate":""}})]},proxy:true},{key:"item.bracketN",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.bracket)+" ")]}},{key:"item.unix",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("unixToLocalDt")(item.unix))+" ")]}},{key:"item.finishInt",fn:function(ref){
var item = ref.item;
return [(item.finish < 9999)?[_vm._v(_vm._s(_vm._f("ordinal")(item.finish)))]:[_vm._v("P")]]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [((_vm.$vuetify.breakpoint.xs && _vm.division.numOfPlayers <= 2 && !_vm.division.useTeamNames) || _vm.tournament.isShowcase)?_c('div',{on:{"click":function($event){$event.stopPropagation();return _vm.teamClick(item.id)}}},_vm._l((item.players),function(p){return _c('div',{key:p.id,staticClass:"noWrap color3--text"},[_vm._v(" "+_vm._s(p.name)+_vm._s(_vm.division.showCommit && p.commitAbbr ? (" (" + (p.commitAbbr) + ")") : '')+" "),(p.isBookmarked(_vm.user))?_c('i',{staticClass:"fas fa-heart ml-2"}):_vm._e()])}),0):_c('div',{staticClass:"color3--text clickable d-flex align-center",on:{"click":function($event){$event.stopPropagation();return _vm.teamClick(item.id)}}},[(_vm.showLogos && item.logo)?_c('v-img',{attrs:{"src":item.logo,"height":"36","max-width":"48","contain":""}}):_vm._e(),_vm._v(" "+_vm._s(item.name)+" "),(item.hasBookmarkedPlayer(_vm.user))?_c('i',{staticClass:"fas fa-heart ml-2"}):_vm._e()],1)]}},{key:"item.captain",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.captains.map(function (m) { return m.name; }).join(' | '))+" ")]}},{key:"item.players",fn:function(ref){
var item = ref.item;
return [_c('div',_vm._l((item.players),function(p){return _c('div',{key:p.id,staticClass:"noWrap d-flex"},[(_vm.showJersey)?_c('div',{staticClass:"text-end mr-2",staticStyle:{"width":"30px","min-width":"30px"}},[_vm._v(_vm._s(p.jersey ? ("#" + (p.jersey)) : null)+" ")]):_vm._e(),_c('div',{staticClass:"d-flex grow justify-space-between"},[_c('div',[_vm._v(" "+_vm._s(p.name)+_vm._s(_vm.division.showCommit && p.commitAbbr ? (" (" + (p.commitAbbr) + ")") : '')+" "),(p.isBookmarked(_vm.user))?_c('i',{staticClass:"fas fa-heart ml-2"}):_vm._e()]),(_vm.showMovr)?_c('div',{staticClass:"caption grey--text ml-2"},[_vm._v(_vm._s(p.movr))]):_vm._e()])])}),0)]}},{key:"item.gradYear",fn:function(ref){
var item = ref.item;
return [((_vm.$vuetify.breakpoint.xs && _vm.division.numOfPlayers <= 2 && !_vm.division.useTeamNames) || _vm.tournament.isShowcase)?_c('div',{on:{"click":function($event){$event.stopPropagation();return _vm.teamClick(item.id)}}},_vm._l((item.players),function(p){return _c('div',{key:p.id},[_vm._v(" "+_vm._s(p.gradYear)+" ")])}),0):_c('div',[_vm._v(" "+_vm._s(item.gradYear)+" ")])]}},{key:"item.commitAbbr",fn:function(ref){
var item = ref.item;
return [((_vm.$vuetify.breakpoint.xs && _vm.division.numOfPlayers <= 2 && !_vm.division.useTeamNames) || _vm.tournament.isShowcase)?_c('div',{on:{"click":function($event){$event.stopPropagation();return _vm.teamClick(item.id)}}},_vm._l((item.players),function(p){return _c('div',{key:p.id,staticStyle:{"min-height":"21px"}},[_vm._v(" "+_vm._s(p.commitAbbr)+" ")])}),0):_c('div',[_vm._v(" "+_vm._s(item.commitAbbr)+" ")])]}},{key:"item.finishPoints",fn:function(ref){
var item = ref.item;
return [(!_vm.$vuetify.breakpoint.xsOnly)?_c('table',{staticClass:"pt nested",attrs:{"width":"100%"}},_vm._l((item.points),function(p){return _c('tr',{key:((p.short) + "-" + (item.id))},[_c('td',{staticClass:"text-right pa-0 pr-1",attrs:{"width":"50%"}},[_vm._v(_vm._s(p.short)+":")]),_c('td',{staticClass:"text-left pa-0",attrs:{"width":"50%"}},[_vm._v(_vm._s(_vm._f("fixed1OrInt")(p.total)))])])}),0):_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm._f("pointArray")(item.points.map(function (m) { return m.total; }))))])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }